<template>
  <nav
    style="--bs-breadcrumb-divider: '>'"
    aria-label="breadcrumb"
    id="breadcrumb"
  >
    <ol class="breadcrumb justify-content-center">
      <li
        :class="{ 'breadcrumb-item': true, active: !item.route }"
        :aria-current="!item.route ? 'page' : null"
        v-for="(item, item_i) in itens"
        :key="item_i"
      >
        <router-link v-if="item.route" :to="item.route">{{
          item.legenda
        }}</router-link>
        <span v-if="!item.route">{{ item.legenda }}</span>
      </li>
    </ol>
  </nav>
</template>
<script>
export default {
  props: {
    itens: {
      type: Array,
      require: true,
    },
  },
};
</script>